export const MACHINE_ENVIRONMENT = "prod"; //"dev", "test", "prod", "rr"
export const RECAPTCHA_ENABLED = "true"; //"false", "true"

export const SERVER_NAME = "moovetrax.com" //"moovetrax.com", "gps.moovetrax.com"
export const APP_NAME = "MooveTrax"

let base_site_url = ""
let base_front_url = ""
let base_api_url = ""
let socket_url = ""
let share_base_url = ""
let share_secondary_base_url = ""
let paypal_client_id = ""
if (MACHINE_ENVIRONMENT === "dev") {
    base_site_url = "http://127.0.0.1"
    base_front_url = "http://127.0.0.1:3000"
    base_api_url = "http://127.0.0.1:8088"
    socket_url = "ws://127.0.0.1:8000/websocket";
    share_base_url = "http://127.0.0.1:3000/temporary/"
    share_secondary_base_url = "http://127.0.0.1:3000/second-temporary/"
    paypal_client_id = "ATQmca5ctIGxK6a8S6nvUhug46UBd680Tq4uQ7WaFXq3e2Ta2EETuchC7B_S-thf5tE5qFTQm800JJbn"
}
else if (MACHINE_ENVIRONMENT === "test") {
    base_site_url = "http://54.204.132.185"
    base_front_url = "http://54.204.132.185"
    base_api_url = "http://54.204.132.185:8088"
    socket_url = "ws://54.204.132.185:8000/websocket";
    share_base_url = "http://54.204.132.185/temporary/"
    share_secondary_base_url = "http://Keyless.live/temporary/"
    paypal_client_id = "ATQmca5ctIGxK6a8S6nvUhug46UBd680Tq4uQ7WaFXq3e2Ta2EETuchC7B_S-thf5tE5qFTQm800JJbn"
}
else if (MACHINE_ENVIRONMENT === "rr") {
    base_site_url = "http://rr.moovetrax.com"
    base_front_url = "http://rr.moovetrax.com"
    base_api_url = "http://rr.moovetrax.com:8088"
    socket_url = "ws://rr.moovetrax.com:8000/websocket";
    share_base_url = "http://rr.MooveTrax.com/temporary/"
    share_secondary_base_url = "http://Keyless.live/temporary/"
    paypal_client_id = ""
}
else if (MACHINE_ENVIRONMENT === "prod") {
    base_site_url = "https://moovetrax.com"
    base_front_url = "https://moovetrax.com"
    base_api_url = "https://moovetrax.com:8088"
    socket_url = "wss://moovetrax.com:8000/websocket";
    share_base_url = "https://MooveTrax.com/temporary/"
    share_secondary_base_url = "https://Keyless.live/temporary/"
    paypal_client_id = "ASMHOIwvglf-2DrR9JeVXx8ewiUUYewDb6-FvZ2rOLhH6TRRTUd2Nep3hLkAEEFYmOXZkaSTnJ3aMpBQ"
}

export const BASE_SITE_URL = base_site_url;
export const BASE_FRONT_URL = base_front_url;
export const API_BASE_URL = base_api_url;
export const SOCKET_URL = socket_url;
export const SHARE_BASE_URL = share_base_url
export const SHARE_SECONDARY_BASE_URL = share_secondary_base_url

export const INSTALLATION_GUIDE_LINK = "http://install.moovetrax.com/"

export const GOOGLE_MAP_API_KEY = "AIzaSyBaDSA1OGhiJ3D3wU4fX1JYPSjUdUEbrjg"; // we use for google api
export const GOOGLE_MAP_KEY = "AIzaSyAEg6PTEHsL_QrE4hEdTchYcmRtvqcqbX4"; // we use for showing google map
export const DEFAULT_GEO_LOCATION = [35.840894, -40.757315]; // [latitude, longitude]
// export const GOOGLE_MAP_API_KEY = "AIzaSyBaDSA1OGhiJ3D3wU4fX1JYPSjUdUEbrjg"; // we use for google api
// export const GOOGLE_MAP_KEY = "AIzaSyAEg6PTEHsL_QrE4hEdTchYcmRtvqcqbX4"; // we use for showing google map
// export const DEFAULT_GEO_LOCATION = [35.840894, -40.757315]; // [latitude, longitude]

export const PAYPAL_CLIENT_ID = paypal_client_id
export const PAYPAL_PRODUCT_ID = "MoovetraxMonthlyProduct"
export const PAYPAL_PLAN_NAME = "MoovetraxMonthlyPlan"
export const PAYPAL_PLAN_PRICE = 15 //USD //default plan price
export const PAYPAL_ESCROW_PLAN_PRICE = 50 //USD //default plan price

export const MAX_CREDIT_AMOUNT = 15 //max allowed credit amount (if credit is larger than this amount, then user will be forced to the paypal page automatically)
export const MIN_CREDIT_AMOUNT = 5  //minimum amount for user to charge paypal, so if amount is larger than this amount, then user can look "Paypal subscription" button

export const GOOGLE_CHROME_LOGO = "/assets/img/icon-chrome-480.png"
export const GOOGLE_CHROME_URL = "https://apps.apple.com/us/app/google-chrome/id535886823"

export const RECAPTCHA_KEY = "6LcU4rolAAAAAOp_JoONzUSt9Sb4ZZqV_3rDEW0K" // "6LcU4rolAAAAAOp_JoONzUSt9Sb4ZZqV_3rDEW0K" "6LeMLwcfAAAAAGZ8Vb5m6UjxUl3wTboTmmKqn4IQ"

export const GPS_ID_PLACEHOLDER = "MT2V000000"
export const USB_GPS_ID_PLACEHOLDER = "3211312220"
export const ICCID_PLACEHOLDER = "002600000"
export const IMAGE_CACHE_VERSION = "1.2"

export const HOME_VIDEO_ID = "v0d-yVgiJwc" //YOUTUBE ID
export const SHRED_TURO_VIDEO_ID = "ISVv_ZYMkaQ" //YOUTUBE ID
export const SHRED_UPLOAD_VIDEO_ID = "Wiks5TRTzBA" //YOUTUBE ID
export const BLUETOOTH_VIDEO_UPLOAD_ID = "mJ5XPDmtx7Y"
export const MAINT_VIDEO_ID = "IgjoIAFUMMs" //YOUTUBE ID
export const TOLL_VIDEO_ID = "RJmkKwgAzbc" //YOUTUBE ID
export const PAYMENT_VIDEO_ID = "iD6_zjMX4pw" //YOUTUBE ID
export const GEOFENCE_VIDEO_ID = "OCz1Oqc8UDc" //YOUTUBE ID

export const SORT_KEY_LIST = [
    "Installation",
    "Alphabetically",
    "Balance",
    "Online",
    // "Maintenance",
    "Lock Status",
    "Kill status",
    "",
    "Make",
    "Model",
    "Color",
    "Category",
    "Miles",
    "Vin"
]
export const DEFAULT_SORT_KEY = SORT_KEY_LIST[0]
export const DEFAULT_SORT_ORDER = "asc" //asc, desc

export const MT2V_DC_VOLT_ALERT = 11 //11 V
export const MT2V_DC_VOLT_MIN = 9 //9 V
export const MT2V_DC_VOLT_MAX = 15 //15 V

export const MOVING_SPEED_MIN = 5
export const SHARED_LINK_TEXT_KEY = "[domain][url]" //"{{shared_url}}"

export const DISTANCE_UNIT_LIST = [
    {
        id: 'mile',
        name: 'Miles',
        unit: 'mi',
        speed_unit: 'mi/h'
    },
    {
        id: 'km',
        name: 'Km',
        unit: 'km',
        speed_unit: 'km/h'
    }
]

export const TEST_MT3V_DEVICE_GPS_IDS = ["MT2V000001"]
export const TESLA_PERMISSION_URL = "https://accounts.tesla.com/en_us/account-settings/security?tab=tpty-apps"
export const DEVICE_TYPE = {
    MOOVETRAX: "moovetrax",
    SMARTCAR: "smartcar",
    TESLA: "tesla",
    USB: "usb",
    OBD: "obd",
}
export const DEFAULT_LOW_BATTERY_VOLT = 11

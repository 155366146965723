import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel, Checkbox, FormControl, Button, Snackbar, Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditDevicesView from './devices/EditDevicesView';
import LinkField from './form/LinkField';
import { useTranslation } from './LocalizationProvider';
import useUserDevices from './devices/useUserDevices';
import { useSelector } from 'react-redux';
import { api_call, console_log, priceFormat } from './helpers/untils';
import { Stack } from '@mui/material';
import EscrowLogsModal from './settings/EscrowLogsModal';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));


const ProfilePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const [item, setItem] = useState();
  // getDevices();
  const [allDevices, setAllDevices] = useState([])
  const user = useSelector((state) => state.session.user);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const onClickResetPassword = async () => {
    let response;
    response = await api_call('/api/password/reset', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: user.email }),
    });
    if (response.ok) {
      setToastMessage(`A password change link has been sent to your email.`)
      setSnackbarOpen(true);
    } else {
      setToastMessage(response['error'])
      setSnackbarOpen(true);
    }
  }

  // escrow modal
  const [escrowLogsModalOpen, setEscrowLogsModalOpen] = useState(false);

  const getUserInfo = async () => {
    let url = `/api/user-profile`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const selectedUser = await response.json()
      return selectedUser
    } else {
      return false
    }
  }

  const showEscrowLogsModal = async () => {
    const userInfo = await getUserInfo()
    setItem(userInfo)
    setEscrowLogsModalOpen(true)
  }

  return (
    <>
      <EditItemView endpoint="user-profile" item={item} setItem={setItem}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          onClose={() => { setSnackbarOpen(false) }}
          autoHideDuration={3000}
          message={toastMessage}
        />

        {item
          && (
            <>
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <Typography variant="subtitle1">
                    Profile
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <TextField
                    margin="normal"
                    value={item.name || ''}
                    onChange={(event) => setItem({ ...item, name: event.target.value })}
                    label={t('sharedName')}
                    variant="filled"
                  />
                  <TextField
                    margin="normal"
                    value={item.email || ''}
                    onChange={(event) => setItem({ ...item, email: event.target.value })}
                    label={t('userEmail')}
                    variant="filled"
                    disabled
                  />

                  <FormControl fullWidth margin="normal">
                    <Button type="button" color="primary" variant="contained" fullWidth onClick={onClickResetPassword}>
                      {`Reset Password`}
                    </Button>
                  </FormControl>

                  <TextField
                    margin="normal"
                    value={item.phone || ''}
                    onChange={(event) => setItem({ ...item, phone: event.target.value })}
                    label={t('sharedPhone')}
                    variant="filled"
                  />

                  <Box sx={{ p: 1, mt: 1 }}>
                    <Stack direction={`row`} spacing={1} sx={{ cursor: 'pointer' }} title="Show escrow logs" onClick={() => showEscrowLogsModal()}>
                      <Typography>Escrow Balance: </Typography>
                      <Typography style={{ fontWeight: '600' }}>${priceFormat(item.escrow_balance ?? 0)}</Typography>
                    </Stack>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          )}
      </EditItemView>
      {
        (item && item.id) && (
          <EscrowLogsModal
            open={escrowLogsModalOpen}
            setOpen={setEscrowLogsModalOpen}
            item={item}
            setItem={setItem}
            modalType={`self`}
          />
        )
      }
    </>
  );
};

export default ProfilePage;

import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import MainPage from './MainPage';
import MainSite from './MainSite'
import ContactSite from './ContactSite'
import ServerPage from './admin/ServerPage';
import UsersPage from './admin/UsersPage';
import DevicesPage from './admin/DevicesPage';
import MissedInvoicePage from './admin/MissedInvoicePage';
import DevicePage from './DevicePage';
import SmartcarDevicePage from './SmartcarDevicePage';

import UserPage from './UserPage';
import LinksPage from './settings/LinksPage';
import PositionPage from './PositionPage';
import EventReportPage from './reports/EventReportPage';
import ReplayPage from './reports/ReplayPage';
import CommandsPage from './reports/CommandsPage';
import TemporaryPage from './TemporaryPage';
import SharePage from './share/SharePage';

import LoginForm from './components/registration/LoginForm';
import RegisterForm from './components/registration/RegisterForm';
import ResetPasswordForm from './components/registration/ResetPasswordForm';

import theme from './theme';
import GeofencesPage from './GeofencesPage';
import GeofencePage from './GeofencePage';
import SetPasswordForm from './components/registration/SetPasswordForm';
import { LocalizationProvider } from './LocalizationProvider';
import { BrowserRouter } from 'react-router-dom';
import { empty, getToken, getUnixTimestampFromTimezone } from './helpers/untils';
import CachePage from './admin/CachePage';
import ApiDocSite from './ApiDocSite';
import UsersDevicesPage from './admin/UsersDevicesPage';
import InstallerLoginForm from './components/registration/InstallerLoginForm';
import DeviceSignupForm from './components/registration/DeviceSignupForm';
import InstallerMainPage from './InstallerMainPage';
import SmartCarCallbackPage from './SmartCarCallbackPage';
import TuroTripsPage from './settings/TuroTripsPage';
import TuroTripTemporaryPage from './TuroTripTemporaryPage';
import TuroTripLinkApprovePage from './TuroTripLinkApprovePage';
import { AuthorizedLayout } from './layout/AuthorizedLayout';
import TestInstallerLoginForm from './components/registration/TestInstallerLoginForm';
import TuroLoginsPage from './settings/TuroLoginsPage';
import UnmatchedDevicesPage from './admin/UnmatchedDevicesPage';
import ShopIntroSite from './ShopIntroSite';
import ProfilePage from './ProfilePage';
import TollListSite from './TollListSite';
import TemporaryLinkApprovePage from './TemporaryLinkApprovePage';
import PricingSite from './PricingSite';
import TestSite from './TestSite';
import LinkPage from './LinkPage';
import WebPageFooter from './WebPageFooter';
import ResellerInventoryPage from './admin/ResellerInventoryPage';
import TermsConditionsSite from './TermsConditionsSite';
import PrivacyPolicySite from './PrivacyPolicySite';
import DisclaimerSite from './DisclaimerSite';
import { SERVER_NAME } from './config/constant';
import GpsMainSite from './GpsMainSite';
import SettingPage from './admin/SettingPage';
import UsersDataTablePage from './admin/UsersDataTablePage';
import LinksDataTablePage from './settings/LinksDataTablePage';

import SocketController from './SocketController';
import InstallerSignupForm from './components/registration/InstallerSignupForm';
import FindInstallerPage from './FindInstallerPage';
import InstallerRatePage from './InstallerRatePage';
import TestSite1 from './TestSite1';
import TestSite2 from './TestSite2';
import TestSite3 from './TestSite3';
import DailyMileageReportPage from './reports/DailyMileageReportPage';
import InstallerLoginFormOld from './components/registration/InstallerLoginFormOld';
import LinksDataTablePageTest from './settings/LinksDataTablePageTest';
import OemPage from './components/registration/OemPage';
import PricingSiteV3 from './PricingSiteV3';
import TeslaApiPage from './TeslaApiPage';
import TeslaDeviceListPage from './TeslaDeviceListPage';
import TemporaryMapPage from './TemporaryMapPage';
import TestDevicePage from './TestDevicePage';
import OemDevicePage from './OemDevicePage';
import OemDeviceNoAuthPage from './OemDeviceNoAuthPage';
import TeslaCallbackPage from './TeslaCallbackPage';
//import SocketClientController from './SocketClientController';

// import { WonderPush } from 'react-wonderpush';

const App = () => {
  const initialized = useSelector((state) => !!state.session.server);
  const token = getToken()
  // const localTimestamp = getUnixTimestampFromTimezone("2023-03-13 16:45:08", "Australia/Sydney")
  // console.log("localTimestamp::::", localTimestamp)

  return (
    // <WonderPush options = {{webKey: "0402d3e177619bd42295016f32154f14226974d99d9840d7b4a0fae629fca27c"}}>
    <LocalizationProvider>
      {
        (SERVER_NAME === 'moovetrax.com') ? (
          <ThemeProvider theme={theme}>
            <WebPageFooter />
            <CssBaseline />
            <SocketController />
            {/* <SocketClientController /> */}
            <Switch>
              <Route exact path="/" component={MainSite} />
              <Route exact path="/contact" component={ContactSite} />
              <Route exact path="/shop-intro" component={ShopIntroSite} />
              <Route exact path="/pricing" component={PricingSiteV3} />

              <Route exact path="/documentation" component={ApiDocSite} />
              <Route exact path="/terms-conditions" component={TermsConditionsSite} />
              <Route exact path="/privacy-policy" component={PrivacyPolicySite} />
              <Route exact path="/disclaimer" component={DisclaimerSite} />

              <Route exact path="/login" component={LoginForm} />
              {/* <Route exact path="/register" component={RegisterForm} /> */}
              <Route exact path="/reset-password" component={ResetPasswordForm} />
              <Route exact path="/set-password" component={SetPasswordForm} />

              <Route exact path="/device-signup" component={DeviceSignupForm} />

              <Route exact path="/oem-signup" component={OemDeviceNoAuthPage} />
              <Route exact path="/oem" component={OemDeviceNoAuthPage} />
              <Route exact path="/OEM" component={OemDeviceNoAuthPage} />

              <Route exact path="/temporary-map/:shareUrl?" component={TemporaryMapPage} />
              <Route exact path="/temporary/:shareUrl?" component={TemporaryPage} />

              <Route exact path="/temporary-replay/:id?" component={ReplayPage} />

              <Route exact path="/link/:approve_type/:action/:shareUrl" component={TemporaryLinkApprovePage} />

              <Route exact path="/installer-signup" component={InstallerSignupForm} />
              <Route exact path="/installer-login" component={InstallerLoginForm} />
              <Route exact path="/installer-login-old" component={InstallerLoginFormOld} />

              <Route exact path="/installer-main" component={InstallerMainPage} />
              <Route exact path="/installer-rate" component={InstallerRatePage} />
              <Route exact path="/find-installer" component={FindInstallerPage} />

              <Route exact path="/test-installer-login" component={TestInstallerLoginForm} />
              <Route exact path="/test-installer-main" component={InstallerMainPage} />

              <Route exact path="/smartcar-callback" component={SmartCarCallbackPage} />
              <Route exact path="/teslaAPI" component={TeslaCallbackPage} />

              <Route exact path="/turo-trip-link/:shareUrl" component={TuroTripTemporaryPage} />
              <Route exact path="/trip-link/:approve_type/:action/:shareUrl" component={TuroTripLinkApprovePage} />


              {/* <Route exact path="/teslaAPI" component={TeslaApiPage} /> */}
              {/* <Route exact path="/test" component={TestSite} /> */}

              <Route exact path="/test1" component={TestSite1} />
              <Route exact path="/test2" component={TestSite2} />
              <Route exact path="/test-camera" component={TestSite3} />

              <Route>
                {
                  empty(token) ? (<LoginForm />) : (
                    !initialized ? (
                      <LinearProgress />
                    ) : (
                      <AuthorizedLayout>
                        <Switch>
                          <Route exact path="/main" component={MainPage} />
                          <Route exact path="/replay/:id?" component={ReplayPage} />
                          <Route exact path="/share/:id?" component={SharePage} />
                          <Route exact path="/position/:id?" component={PositionPage} />
                          <Route exact path="/device/:id?" component={DevicePage} />
                          {/* <Route exact path="/test-device/:id?" component={TestDevicePage} /> */}
                          <Route exact path="/oem-device/:id?" component={OemDevicePage} />

                          <Route exact path="/geofence/:id?" component={GeofencePage} />
                          <Route exact path="/geofences/:id?" component={GeofencesPage} />
                          <Route exact path="/settings/links" component={LinksDataTablePage} />
                          {/* <Route exact path="/settings/links-test" component={LinksDataTablePageTest} /> */}
                          <Route exact path="/link/:id?" component={LinkPage} />
                          <Route exact path="/settings/turo-trips" component={TuroTripsPage} />
                          <Route exact path="/escrow-logs" component={UserPage} />
                          <Route exact path="/smartcar-device/:id?" component={SmartcarDevicePage} />
                          <Route exact path="/tesla-device/:id?" component={TeslaDeviceListPage} />

                          <Route exact path="/settings/turo-logins" component={TuroLoginsPage} />
                          <Route exact path="/toll-list/:searchId" component={TollListSite} />
                          <Route exact path="/reports/commands/:id?" component={CommandsPage} />
                          <Route exact path="/reports/event/:id?" component={EventReportPage} />
                          <Route exact path="/reports/daily-mileage/:id?" component={DailyMileageReportPage} />
                          <Route exact path="/reports/chart" component={TestSite1} />

                          <Route exact path="/profile" component={ProfilePage} />
                          <Route exact path="/user/:id?" component={UserPage} />

                          {/* <Route exact path="/admin/server" component={ServerPage} /> */}
                          <Route exact path="/admin/users" component={UsersDataTablePage} />
                          {/* <Route exact path="/admin/users-data-table" component={UsersDataTablePage} /> */}
                          <Route exact path="/admin/devices" component={DevicesPage} />
                          <Route exact path="/admin/add-users-devices" component={UsersDevicesPage} />
                          <Route exact path="/admin/unmatched-devices" component={UnmatchedDevicesPage} />
                          <Route exact path="/admin/missed-invoices" component={MissedInvoicePage} />
                          <Route exact path="/admin/cache" component={CachePage} />
                          <Route exact path="/admin/reseller-inventory" component={ResellerInventoryPage} />
                          <Route exact path="/admin/setting" component={SettingPage} />

                        </Switch>
                      </AuthorizedLayout>
                    )
                  )
                }
              </Route>
            </Switch>
          </ThemeProvider>
        ) : (SERVER_NAME === 'gps.moovetrax.com') ? (
          <ThemeProvider theme={theme}>
            <WebPageFooter />
            <CssBaseline />
            <Switch>
              <Route path="/" component={GpsMainSite} />
            </Switch>
          </ThemeProvider>
        ) : (
          <></>
        )
      }
    </LocalizationProvider>
    // </WonderPush>
  );
};

export default App;


import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import maplibregl from 'maplibre-gl';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { map } from './Map';
import store, { devicesActions, layoutActions } from '../store';
import StatusView from './StatusView';
import theme from '../theme';
import moment from 'moment';
import { api_call, console_log, empty, getAddressFromGoogleGeoLocation, get_data_value, get_utc_timestamp_ms } from '../helpers/untils';
import TemporaryStatusView from './TemporaryStatusView';
import { DEVICE_TYPE, GOOGLE_MAP_API_KEY, MACHINE_ENVIRONMENT } from '../config/constant';
import { checkDeviceCanBeShownInMap, checkDeviceChargeRequired, checkDeviceIsActive, checkShowPaypalBanner } from '../helpers/misc';
import { getIsAdmin } from '../common/selectors';

const PositionsMap = (props) => {
    const { positions, showStopSign, temporaryPage, currentLink, loadLinkInfo, pageType, showPaypalCreditLogsPage, commands, enablePopup = true, isTest = false } = props;


    const [stopSignPositions, setStopSignPositions] = useState([])

    const id = 'positions';
    const clusters = `${id}-clusters`;

    const dispatch = useDispatch();
    const history = useHistory();
    const isAdmin = useSelector(getIsAdmin);

    const devices = useSelector((state) => state.devices.items);

    //console_log("state.devices.items:::", devices)

    const deviceColor = (device) => {
        // switch (device.status) {
        //   case 'online':
        //     return 'green';
        //   case 'offline':
        //     return 'red';
        //   default:
        //     return 'gray';
        // }
        return device.color;
    };

    const createFeature = (devices, position) => {
        const device = devices[position.deviceId];
        if (empty(device)) {
            return null
        }

        let name = device.name;
        if (device.deviceType === DEVICE_TYPE.SMARTCAR || device.deviceType === DEVICE_TYPE.TESLA || device.deviceType === DEVICE_TYPE.USB || device.deviceType === DEVICE_TYPE.OBD) {
            if (position.speed) {
                name = name + " : " + position.speed + "mph";
            }
        } else {
            if (moment().diff(moment(device.lastPosition), "seconds") < 5 * 60) {
                if (position.speed) {
                    name = name + " : " + position.speed + "mph";
                }
            }
        }

        const acc = (device.acc && device.status === 'online') ? 1 : 0
        return {
            deviceId: position.deviceId,
            name: name,
            category: device.category ? device.category : 'Default',
            color: device.color || 'Gray',
            status: device.status,
            acc: acc,
            device: device,
            position: position,
            textOffset: [0, acc ? -3.75 : -2]
        }
    }

    const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
    const onMouseLeave = () => map.getCanvas().style.cursor = '';

    const onMarkerClick = useCallback(async (event) => {
        let userDeviceItem = null

        if (!enablePopup) {
            return false
        }

        setPopupOpened(true)
        const feature = event.features[0];
        //console_log("feature::::::", feature)
        let deviceInfo = feature.properties.device;
        if (empty(deviceInfo)) {
            return false
        }
        deviceInfo = JSON.parse(deviceInfo)
        //console_log("deviceInfo::::", deviceInfo)

        let position = feature.properties.position;
        if (!empty(position)) {
            position = JSON.parse(position)
            //console_log("position::::", position)
            event.lngLat.lat = position.latitude;
            event.lngLat.lng = position.longitude;
        }

        if (temporaryPage) {
            //continue: shared link does not check charge required
        } else {
            ////////////////////////start paypal credit log modal//////////////////////////////////////
            // we only suggest subscriotion // 
            const url = `/api/device-check-charge-required/${deviceInfo.id}`
            const apiRes = await api_call(url);
            if (apiRes.ok) {
                const apiResObj = await apiRes.json()
                //console.log("apiResObj::::", apiResObj)
                userDeviceItem = apiResObj['device']
                const chargeRequired = apiResObj['charge_required']
                // if (chargeRequired) {
                //   if (typeof showPaypalCreditLogsPage === 'function') {
                //     if (checkShowPaypalBanner(deviceInfo.id)) {
                //       showPaypalCreditLogsPage(userDeviceItem)
                //       return false;
                //     }
                //   }
                // }

                if (chargeRequired == 1) {
                    if (typeof showPaypalCreditLogsPage === 'function') {
                        showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
                    }
                } else if (chargeRequired == 2) {
                    if (checkShowPaypalBanner(deviceInfo.id)) {
                        showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
                        return false;
                    }
                }
            } else {
                alert("Invalid request")
                return false
            }

            // we force user to subscriotion // 
            // const chargeRequired = checkDeviceChargeRequired(deviceInfo, isAdmin)
            // if (chargeRequired == 1) {
            //   dispatch(layoutActions.showCreditLogsModal());
            //   if (typeof showPaypalCreditLogsPage === 'function') {
            //     showPaypalCreditLogsPage(userDeviceItem)
            //   }
            // } else if (chargeRequired == 2) {
            //   dispatch(layoutActions.closeCreditLogsModal());
            //   if (typeof showPaypalCreditLogsPage === 'function') {
            //     showPaypalCreditLogsPage(userDeviceItem)
            //     return false;
            //   }
            // } else {
            //   dispatch(layoutActions.closeCreditLogsModal());
            // }
        }

        ////////////////////////end paypal credit log modal//////////////////////////////////////

        const coordinates = [deviceInfo.longitude, deviceInfo.latitude]  //feature.geometry.coordinates.slice();
        //console_log("coordinates::::", coordinates)
        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        /////////////////////////////////////////////////////////////////////////////
        const selectedDeviceId = feature.properties.deviceId
        const selectedDevice = devices[selectedDeviceId]

        if (empty(selectedDevice)) {
            return false
        }
        //map.easeTo({ zoom: 16 });
        dispatch(devicesActions.select(selectedDevice));
        dispatch(layoutActions.closeDeviceListMenu());
        dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));

        /////////////////////////////////////////////////////////////////////////////
        const placeholder = document.createElement('div');
        ReactDOM.render(
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    {
                        (temporaryPage) ? (
                            <TemporaryStatusView
                                currentLink={currentLink}
                                loadLinkInfo={loadLinkInfo}
                                deviceId={feature.properties.deviceId}
                                address={``}
                                lat={event.lngLat.lat}
                                lng={event.lngLat.lng}
                                onShowDetails={(positionId) => history.push(`/position/${positionId}`)}
                                onShowHistory={(deviceId) => history.push(`/temporary-replay/${deviceId}`)}
                                onShareLink={(deviceId) => history.push(`/share/${deviceId}`)}
                                onEditClick={(deviceId) => history.push(`/device/${deviceId}`)}
                                onLogClick={(deviceId) => history.push(`/reports/event/${deviceId}`)}
                                history={history}
                                commandsData={commands}
                                userDeviceItem={userDeviceItem}
                                isTest={isTest}
                            />
                        ) : (
                            <StatusView
                                deviceId={feature.properties.deviceId}
                                address={``}
                                lat={event.lngLat.lat}
                                lng={event.lngLat.lng}
                                onShowDetails={(positionId) => history.push(`/position/${positionId}`)}
                                onShowHistory={(deviceId) => history.push(`/replay/${deviceId}`)}
                                onShareLink={(deviceId) => history.push(`/share/${deviceId}`)}
                                onEditClick={(deviceId) => history.push(`/device/${deviceId}`)}
                                onLogClick={(deviceId) => history.push(`/reports/event/${deviceId}`)}
                                history={history}
                                userDeviceItem={userDeviceItem}
                            />
                        )
                    }
                </ThemeProvider>
            </Provider>,
            placeholder,
        );

        const mk = new maplibregl.Popup({
            offset: 25,
            anchor: 'top',
        })
            .setDOMContent(placeholder)
            .setLngLat(coordinates)
            .addTo(map);

    }, [history]);

    const onClusterClick = (event) => {
        const features = map.queryRenderedFeatures(event.point, {
            layers: [clusters],
        });
        const clusterId = features[0].properties.clusterid;
        map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
            if (!error) {
                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom,
                });
            }
        });
    };

    useEffect(() => {
        map.addSource(id, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
        });
        map.addLayer({
            id,
            type: 'symbol',
            source: id,
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': '{category}-{color}-{acc}',
                'icon-allow-overlap': true,
                'text-field': '{name}',
                'text-allow-overlap': true,
                'text-anchor': 'bottom',
                'text-offset': ['get', 'textOffset'], //'text-offset': [0, -2],
                'text-font': ['Open Sans Regular'], //https://github.com/openmaptiles/fonts/tree/master/open-sans
                'text-size': 12,
            },
            paint: {
                'text-halo-color': 'white',
                'text-halo-width': 1,
            },
        });
        map.addLayer({
            id: clusters,
            type: 'symbol',
            source: id,
            filter: ['has', 'point_count'],
            layout: {
                'icon-image': 'background',
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Open Sans Regular'], //https://github.com/openmaptiles/fonts/tree/master/open-sans
                'text-size': 14,
            },
        });

        map.on('mouseenter', id, onMouseEnter);
        map.on('mouseleave', id, onMouseLeave);
        map.on('mouseenter', clusters, onMouseEnter);
        map.on('mouseleave', clusters, onMouseLeave);
        map.on('click', id, onMarkerClick);
        map.on('click', clusters, onClusterClick);

        return () => {
            Array.from(map.getContainer().getElementsByClassName('maplibregl-popup')).forEach((el) => el.remove());

            map.off('mouseenter', id, onMouseEnter);
            map.off('mouseleave', id, onMouseLeave);
            map.off('mouseenter', clusters, onMouseEnter);
            map.off('mouseleave', clusters, onMouseLeave);
            map.off('click', id, onMarkerClick);
            map.off('click', clusters, onClusterClick);

            if (map.getLayer(id)) {
                map.removeLayer(id);
            }
            if (map.getLayer(clusters)) {
                map.removeLayer(clusters);
            }
            if (map.getSource(id)) {
                map.removeSource(id);
            }
        }
    }, [onMarkerClick]);

    const [curPosition, setCurPosition] = useState({})
    const [popupOpened, setPopupOpened] = useState(false)

    const checkFeatureIsActive = (feature) => {
        const deviceInfo = feature.properties.device;
        let isActive = false
        if (temporaryPage) {
            isActive = true
        } else {
            //isActive = checkDeviceIsActive(deviceInfo);
            // check device can be shown in map
            isActive = checkDeviceCanBeShownInMap(deviceInfo);
        }

        if (pageType === "replay") {
            isActive = true
        }
        if (MACHINE_ENVIRONMENT === 'dev' || MACHINE_ENVIRONMENT === 'test') {
            isActive = true
        }
        return isActive
    }

    useEffect(() => {
        if (!empty(devices) && !empty(positions)) {
            const featureList = positions.reduce((filtered, position) => {
                const properties = createFeature(devices, position)
                if (properties) {
                    const feature = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [position.longitude, position.latitude],
                        },
                        properties: properties,
                    }

                    let isActive = checkFeatureIsActive(feature)
                    if (isActive) {
                        filtered.push(feature);
                    }
                }
                return filtered;
            }, []);
            // console_log("featureList::::", featureList);

            map.getSource(id).setData({
                type: 'FeatureCollection',
                features: featureList,
            });

            if (temporaryPage) {
                positions.filter((it) => devices.hasOwnProperty(it.deviceId)).map((position) => {
                    setCurPosition(position)
                })
            }
        }
    }, [devices, positions, pageType]);

    useEffect(() => {
        if (temporaryPage) {
            //console.log("temporaryPage, curPosition::::::", curPosition)
            if (!empty(curPosition) && curPosition.id && !empty(devices)) {
                if (!popupOpened) {
                    //console.log("map.fire::::::", popupOpened)
                    map.fire('click', {
                        lngLat: new maplibregl.LngLat(curPosition.longitude, curPosition.latitude)
                    });
                }
            }
        }
    }, [curPosition, devices]);

    const stopSignLayer = 'stop-sign-layer'; // Unique ID for the layer
    const stopSignSource = 'stop-sign-source'; // Unique ID for the source
    useEffect(() => {
        // Add the stop sign image to the map
        if (!map.hasImage('stop-sign-icon')) {
            map.loadImage('/images/stop_sign.png', (error, image) => {
                if (error) {
                    console.error('Error loading image:', error);
                    return;
                }
                if (image) {
                    map.addImage('stop-sign-icon', image, { width: 50, height: 20 });
                }
            });
        }

        // Add the GeoJSON source
        if (!map.getSource(stopSignSource)) {
            map.addSource(stopSignSource, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                },
            });
        }

        // Add the layer to display the stop sign icon
        if (!map.getLayer(stopSignLayer)) {
            map.addLayer({
                id: stopSignLayer,
                type: 'symbol',
                source: stopSignSource,
                layout: {
                    'icon-image': 'stop-sign-icon',
                    'icon-size': 0.06,
                    'icon-text-fit': 'both',
                    'icon-allow-overlap': true, // Allow multiple icons to overlap
                },
            });
        }
        return () => {
            // Cleanup the layer and source on component unmount
            // if (map.getLayer(stopSignLayer)) {
            //     map.removeLayer(stopSignLayer);
            // }
            // if (map.getSource(stopSignSource)) {
            //     map.removeSource(stopSignSource);
            // }
            // if (map.hasImage('stop-sign-icon')) {
            //     map.removeImage('stop-sign-icon');
            // }
        };

    }, []);

    useEffect(() => {
        // Update the GeoJSON source with the positions when `showStopSign` is true
        if (showStopSign && positions && map.getSource(stopSignSource)) {
            // Create a single feature for the stop sign (since you only want to show one at a time)
            setStopSignPositions((prev) => [...prev, {
                longitude: positions[0].longitude,
                latitude: positions[0].latitude,
            }])
        }
    }, [showStopSign, positions]);

    useEffect(() => {
        if (stopSignPositions.length && map.getSource(stopSignSource)) {
            // Create an array of features from stopSignPositions
            const features = stopSignPositions.map((item) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [item.longitude, item.latitude],
                },
                properties: {}, // Add any properties if needed
            }));

            // Update the GeoJSON source with all features
            map.getSource(stopSignSource).setData({
                type: 'FeatureCollection',
                features, // Use the array of features
            });
        }
    }, [stopSignPositions]);

    return null;
};

export default PositionsMap;

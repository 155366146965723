import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles, Button, Box, Typography,
  Link,
  Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import ModalVideo from 'react-modal-video';
import moment from 'moment';
import { api_call, empty, isContainsOnlyDigits } from '../helpers/untils';
import { Stack } from '@mui/material';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import { APP_NAME, BLUETOOTH_VIDEO_UPLOAD_ID, SHRED_UPLOAD_VIDEO_ID } from '../config/constant';
import LockUnlockSettingModal from '../settings/LockUnlockSettingModal';

const SIGNAL_MIN = 1
const SIGNAL_MAX = 15
const SignalMarks = [
  {
    value: SIGNAL_MIN,
    label: 'Weak',
  },
  {
    value: SIGNAL_MAX,
    label: 'Strong',
  },
];

const DISTANCE_MIN = 20
const DISTANCE_MAX = 120
const DistanceMarks = [
  {
    value: DISTANCE_MIN,
    label: 'Near',
  },
  {
    value: DISTANCE_MAX,
    label: 'Far',
  },
];

const useStyles = makeStyles((theme) => ({
  bluetoothModal: {
    // width: '600px',
  },
  bluetoothConfirmModal: {
    width: '400px',
    margin: 'auto'
  },
}));

const BtCommandSendDialog = (props) => {
  const { openBluetoothChild, setOpenBluetoothChild, device, userDeviceItem, userInfo } = props;
  const dispatch = useDispatch();

  const user = userInfo ?? useSelector((state) => state.session.user);
  //console.log("user:::", user)
  const classes = useStyles();

  const BT_NAME_LENGTH = 15
  const BT_PIN_LENGTH = 6

  const showToast = (msg) => {
    window.alert(msg)
  }

  const handleBluetoothChildClose = () => {
    setOpenBluetoothChild(false);
  };

  const [videoOpen, setVideoOpen] = useState(false);
  const onClickShowVideo = () => {
    setVideoOpen(true)
  }

  ////////////////////////////////////////////////////
  const [apiLoading, setApiLoading] = useState(false)

  const defaultFormData = {
    mt2v_bt_status: false,
    mt2v_bt_name: "",
    mt2v_bt_pin: "",
    mt2v_bt_signal: SIGNAL_MIN,
    mt2v_bt_distance: DISTANCE_MIN
  }
  const [formData, setFormData] = useState(defaultFormData)

  useEffect(() => {
    if (userDeviceItem) {
      const form_data = {
        mt2v_bt_status: userDeviceItem.mt2v_bt_status,
        mt2v_bt_name: userDeviceItem.mt2v_bt_name,
        mt2v_bt_pin: userDeviceItem.mt2v_bt_pin,
        mt2v_bt_signal: userDeviceItem.mt2v_bt_signal,
        mt2v_bt_distance: userDeviceItem.mt2v_bt_distance
      }
      setFormData(form_data)
    }
  }, [userDeviceItem])


  const handleChangeStatus = (e) => {
    const checked = e.target.checked
    const form_data = { ...formData }
    form_data['mt2v_bt_status'] = checked
    setFormData(form_data)
  }
  const onChangeText = (e, field_name) => {
    let value = e.target.value
    if (field_name === 'mt2v_bt_name') {
      if (value.length > BT_NAME_LENGTH) {
        value = value.substring(0, BT_NAME_LENGTH)
      }
    }
    else if (field_name === 'mt2v_bt_pin') {
      if (value.length > BT_PIN_LENGTH) {
        value = value.substring(0, BT_PIN_LENGTH)
      }
    }
    const form_data = { ...formData }
    form_data[field_name] = value
    setFormData(form_data)
  }

  const handleChangeSlider = (e, value, field_name) => {
    const form_data = { ...formData }
    form_data[field_name] = value //form_data['mt2v_bt_signal'] = value
    setFormData(form_data)
  }

  const checkFormValidation = () => {
    const form_data = { ...formData }
    if (empty(form_data['mt2v_bt_name'])) {
      showToast("Bluetooth name is empty")
      return false
    }
    if (form_data['mt2v_bt_name'].length > BT_NAME_LENGTH) {
      showToast(`Bluetooth name can contain up to ${BT_NAME_LENGTH} characters`)
      return false
    }
    if (/^[A-Za-z0-9\-]*$/.test(form_data['mt2v_bt_name']) === false) {
      showToast("Bluetooth name must contain letters and numbers and hyphen only")
      return false
    }
    const pinCheck = isContainsOnlyDigits(form_data['mt2v_bt_pin']) && form_data['mt2v_bt_pin'].length === BT_PIN_LENGTH
    if (!pinCheck) {
      showToast(`PIN must contain ${BT_PIN_LENGTH} digits only`)
      return false
    }
    return true
  }

  const checkPaymentRequired = () => {
    //return false
    // if (user.administrator) {
    //   return false
    // }

    // const curMoment = moment();
    // const deviceMoment = moment(userDeviceItem.createdAt)
    // let timeDiff = curMoment.diff(deviceMoment, "days");
    // if (timeDiff > 30) {
    //   return true
    // } else {
    //   return false
    // }
  }

  const handleSaveBluetoothSetting = () => {
    const isValid = checkFormValidation()
    if (isValid) {
      // const paymentRequired = checkPaymentRequired()
      // if (paymentRequired) {
      //   setShowConfirmDialog(true)
      // } else {
      //   saveBluetoothSetting(false)
      // }
      saveBluetoothSetting(false)
    }
  }

  const saveBluetoothSetting = async (paymentRequired = false) => {
    setApiLoading(true)
    setNotificationShow(true)
    setShowConfirmDialog(false)
    let url = `/api/devices/update-bluetooth-setting`;
    const data = {
      deviceId: device.id,
      bluetooth_setting: formData,
      paymentRequired: paymentRequired ? '1' : '0'
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const resData = await response.json();
      handleBluetoothChildClose()
      //dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));
    } else {
      const errorObj = response
      if (errorObj['error']) {
        showToast(errorObj['error'])
      }
    }
    setApiLoading(false)
    setNotificationShow(false)
  }

  const [notificationShow, setNotificationShow] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState("Please wait...")

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const handleConfirmDialogClose = () => {
    setShowConfirmDialog(false);
  }
  const handleConfirmDialogAgree = () => {
    saveBluetoothSetting(true)
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [lockUnlockDevice, setLockUnlockDevice] = useState(null)
  const loadMt2vLockUnlockSetting = async () => {
    const url = `/api/devices/${userDeviceItem.id}`;
    setApiLoading(true)
    let response = await api_call(url);
    setApiLoading(false)
    if (response.ok) {
      const apiData = await response.json()
      setLockUnlockDevice(apiData)
    } else {
      showToast("Invalid request")
    }
  }

  const [showLockUnlockModal, setShowLockUnlockModal] = useState(false)
  const onClickLockUnlockSetting = async () => {
    await loadMt2vLockUnlockSetting()
    setShowLockUnlockModal(true)
  }

  return (
    <>
      <Dialog fullWidth={true} maxWidth={`xs`} open={openBluetoothChild} onClose={handleBluetoothChildClose}>

        <DialogTitle>{`Bluetooth`}</DialogTitle>
        <DialogContent>
          <Box className={classes.bluetoothModal}>
            <Stack direction={`column`} spacing={3}>
              {/* <Box>
                      <DialogContentText>
                        {`Bluetooth setting`}
                      </DialogContentText>
                    </Box> */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Stack direction={`row`} alignItems={`center`} justifyContent={`flex-start`} spacing={2}>
                  <DialogContentText style={{ marginBottom: 0 }}>
                    {`Status`}
                  </DialogContentText>
                  <Switch
                    checked={formData['mt2v_bt_status']}
                    onChange={handleChangeStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>
                    {formData['mt2v_bt_status'] ? 'On' : 'Off'}
                  </Typography>
                </Stack>
                <Stack sx={{ display: 'flex', flexDirection: 'row', mr: 2, alignItems: 'center' }}>
                  <Tooltip
                    arrow
                    title="Renter will be forced to upload some pictures in order to access the car"
                    style={{ marginRight: '10px' }}
                  >
                    <InfoIcon color="action" />
                  </Tooltip>
                  <Typography variant='p' onClick={() => onClickShowVideo()} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff' }}>Video</Typography>
                </Stack>
              </Box>
              <Box>
                <TextField
                  id="bluetooth-name"
                  label={`Bluetooth`}
                  fullWidth
                  variant="standard"
                  value={formData['mt2v_bt_name']}
                  onChange={(e) => { onChangeText(e, 'mt2v_bt_name') }}
                />
              </Box>
              <Box>
                <TextField
                  id="bluetooth-pin"
                  label={`Pin`}
                  fullWidth
                  variant="standard"
                  value={formData['mt2v_bt_pin']}
                  inputProps={{
                    type: 'number',
                    min: 0
                  }}
                  onChange={(e) => { onChangeText(e, 'mt2v_bt_pin') }}
                />
              </Box>
              <Box>
                <DialogContentText>
                  {`Signal`}
                </DialogContentText>
                <Box sx={{ px: 2.5, py: 0 }}>
                  <Slider
                    aria-label="Signal"
                    //defaultValue={formData['mt2v_bt_signal']}
                    getAriaValueText={(valuetext) => { return valuetext }}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={SignalMarks}
                    min={SIGNAL_MIN}
                    max={SIGNAL_MAX}
                    value={formData['mt2v_bt_signal']}
                    onChange={(e, value) => handleChangeSlider(e, value, "mt2v_bt_signal")}
                  />
                </Box>
              </Box>
              <Box>
                <DialogContentText>
                  {`Distance`}
                </DialogContentText>
                <Box sx={{ px: 2.5, py: 0 }}>
                  <Slider
                    aria-label="Distance"
                    //defaultValue={formData['mt2v_bt_signal']}
                    getAriaValueText={(valuetext) => { return valuetext }}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={DistanceMarks}
                    min={DISTANCE_MIN}
                    max={DISTANCE_MAX}
                    value={formData['mt2v_bt_distance']}
                    onChange={(e, value) => handleChangeSlider(e, value, "mt2v_bt_distance")}
                  />
                </Box>
              </Box>
              <Box>
                <div className={classes.normalColumn}>
                  <Button variant="text" onClick={() => onClickLockUnlockSetting()}>{`Lock and Unlock settings >>`}</Button>
                </div>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={apiLoading} onClick={handleBluetoothChildClose}>Cancel</Button>
          <Button disabled={apiLoading} onClick={handleSaveBluetoothSetting}>Save</Button>
        </DialogActions>

        {/* <Snackbar
          style={{ height: "100%", zIndex: 99999 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={notificationShow}
          message={notificationMessage}
          onClose={() => { setNotificationShow(false) }}
        /> */}

      </Dialog>
      <React.Fragment>
        {
          (videoOpen) ? (
            <Dialog fullWidth={true} maxWidth={`xs`} open={true}>
              <ModalVideo
                channel='youtube'
                autoplay={1}
                youtube={{
                  autoplay: 1,
                  mute: 1
                }}
                isOpen={videoOpen}
                videoId={BLUETOOTH_VIDEO_UPLOAD_ID}
                onClose={() => setVideoOpen(false)}
              />
            </Dialog>
          ) : (<></>)
        }

      </React.Fragment>

      {
        (showConfirmDialog) ? (
          <Dialog fullWidth={true} maxWidth={`xs`} open={showConfirmDialog} onClose={handleConfirmDialogClose} className={classes.bluetoothConfirmModal}>
            <DialogTitle>{APP_NAME}</DialogTitle>
            <DialogContent>
              <Box>
                <Stack direction={`column`} spacing={3}>
                  <Box>
                    <DialogContentText color='primary'>
                      {`Cost to change Bluetooth is $1.`}
                    </DialogContentText>
                  </Box>
                </Stack>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button disabled={apiLoading} onClick={handleConfirmDialogClose}>Cancel</Button>
              <Button disabled={apiLoading} onClick={handleConfirmDialogAgree}>Agree</Button>
            </DialogActions>
          </Dialog>
        ) : (<></>)
      }

      {
        (showLockUnlockModal) && (lockUnlockDevice) && (
          <LockUnlockSettingModal
            open={showLockUnlockModal}
            setOpen={setShowLockUnlockModal}
            item={lockUnlockDevice}
            setItem={setLockUnlockDevice}
          />
        )
      }

    </>
  );
}

export default BtCommandSendDialog;
